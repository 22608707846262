const SEARCH_BAR_DATA = [
  ['Prodaja', 'Izdavanje'],
  ['Stan', 'Kuća', 'Lokal'],
  ['Garsonjera', 'Jednosoban', 'Dvosoban', 'Trosoban', 'Svi tipovi'],
  ['Kraljevo', 'Vrnjačka banja', 'Beograd'],
];

export {
  SEARCH_BAR_DATA, //eslint-disable-line
};

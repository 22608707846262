import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import animateScrollTo from 'animated-scroll-to';
import { ReactComponent as Pin } from '../../assets/svg/pin.svg';
import ViewWrapper from '../ViewWrapper';
import DetailPageGallery from '../../components/DetailPageCarousel/DetailPageCarousel';
import DetailPageDescription from '../../components/DetailPageDescription/DetailPageDescription';

function getPropertyData(data, id) {
  return data.find((property) => {
    return property.id === id;
  });
}

const DetailPage = ({ data, match }) => {
  useEffect(() => {
    animateScrollTo(0);
  });

  const property = getPropertyData(data, match.params.id);
  const {
    Naziv,
    Lokacija,
    Fotografije,
  } = property.fields;
  const price = property.fields['Cena (€)'];
  const type = property.fields['Tip Oglasa'];

  return (
    <ViewWrapper isMenuFixed>
      <div className='DetailPage'>
        <div className='Container'>
          <div className='DetailPage-content'>
            <div className='DetailPage-header'>
              <h1 className='DetailPage-title'>
                { Naziv }
              </h1>
              <div className='DetailPage-detailsWrapper'>
                <span className='DetailPage-price'>{ `${price}€` }</span>
                <span className='DetailPage-type'>{ type }</span>
                <span className='DetailPage-location'>
                  <Pin className='DetailPage-pin' />
                  { Lokacija }
                </span>
              </div>
            </div>
            { Fotografije && <DetailPageGallery images={ Fotografije } /> }
            <DetailPageDescription data={ property.fields } />
          </div>
        </div>
      </div>
    </ViewWrapper>
  );
};

DetailPage.propTypes = {
  data: PropTypes.array,
  match: PropTypes.object,
};

export default DetailPage;

import React from 'react';
import InfoCard from '../InfoCard/InfoCard';
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';
import { ReactComponent as Employee } from '../../assets/svg/employee.svg';
import { ReactComponent as Invoice } from '../../assets/svg/invoice.svg';

const CARDS_DATA = [
  {
    alt: 'calendar',
    icon: Calendar,
    title: 'Preko dve decenije u poslu',
    text: 'Agencija za promet nepokretnosti Merkur započela je sa radom 1994. godine. Sedište Agencije se nalazi u Kraljevu.', // eslint-disable-line
  },
  {
    alt: 'employee',
    icon: Employee,
    title: 'Nudimo i pravne usluge',
    text: 'Agencija za promet nepokretnosti Merkur već dugi niz godina saradjuje sa advokatom Zoricom Dobrosavljević iz Kraljeva, koja pruža sve potrebne pravne usluge vezane za promet nepokretnosti.', // eslint-disable-line
  },
  {
    alt: 'invoice',
    icon: Invoice,
    title: 'Težak posao prepustite nama',
    text: 'U saradnji sa bankama pružamo kompletnu uslugu. Proveravamo legalnost nekretnina, obezbeđujemo pravnu sigurnost.', // eslint-disable-line
  },
];

const renderCards = () => {
  return CARDS_DATA.map((card) => {
    return (
      <InfoCard
        key={ card.alt }
        alt={ card.alt }
        icon={ card.icon }
        title={ card.title }
        text={ card.text }
      />
    );
  });
};

const AboutUs = () => {
  return (
    <div className='AboutUs'>
      { renderCards() }
    </div>
  );
};

export default AboutUs;

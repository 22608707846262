import React from 'react';

const Loader = () => {
  return (
    <div className='Loader-wrapper'>
      <div className='Loader'>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  );
}

export default Loader;
import React from 'react';
import PropTypes from 'prop-types';

const Track = ({
  source,
  target,
  getTrackProps,
}) => (
  <div
    style={ {
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    } }
    className='Track'
    { ...getTrackProps() }
  />
);

Track.propTypes = {
  getTrackProps: PropTypes.func,
  source: PropTypes.object,
  target: PropTypes.object,
};

const Handle = ({
  handle: { id, percent },
  getHandleProps,
}) => (
  <div
    className='Handle'
    style={ { left: `${percent}%` } }
    { ...getHandleProps(id) }
  />
);

Handle.propTypes = {
  handle: PropTypes.object,
  getHandleProps: PropTypes.func,
};

export {
  Handle,
  Track,
};

function getPublishingDate(date) {
  const today = new Date();
  const publishingDate = new Date(date);
  const differenceInSeconds = Math.abs(today - publishingDate) / 1000;
  const days = Math.floor(differenceInSeconds / 86400);

  let formatedPublishingDate = `pre ${days} dana`;

  if (days === 0) {
    formatedPublishingDate = 'danas';
  }

  if (days > 7) {
    const day = publishingDate.getDate();
    const month = publishingDate.getMonth();
    const year = publishingDate.getFullYear();

    formatedPublishingDate = `${day}/${month + 1}/${year}`;
  }

  return formatedPublishingDate;
}

function getSortedRecords(data, numberOfRecords) {
  const sortedRecords = data.sort((firstRecord, secondRecord) => {
    const firstRecordDate = firstRecord.fields['Datum Objave'];
    const secondRecordDate = secondRecord.fields['Datum Objave'];

    return new Date(secondRecordDate) - new Date(firstRecordDate);
  });

  return sortedRecords.slice(0, numberOfRecords);
}

function getFilteredRecords(data, filters) {
  let filteredData = data;

  if (filters.activity) {
    filteredData = filteredData.filter((record) => {
      return record.fields['Tip Oglasa'] === filters.activity;
    });
  }

  if (filters.type) {
    filteredData = filteredData.filter((record) => {
      const type = record.fields['Tip']; //eslint-disable-line
      return type === filters.type || type === 'Svi tipovi';
    });
  }

  if (filters.structure) {
    filteredData = filteredData.filter((record) => {
      return record.fields['Struktura'] === filters.structure; //eslint-disable-line
    });
  }

  if (filters.location) {
    filteredData = filteredData.filter((record) => {
      return record.fields['Grad'] === filters.location; //eslint-disable-line
    });
  }
  if (filters.minPrice && filters.maxPrice) {
    filteredData = filteredData.filter((record) => {
      const price = record.fields['Cena (€)'];
      return filters.minPrice <= price && price <= filters.maxPrice;
    });
  }

  return filteredData;
}

export {
  getPublishingDate,
  getSortedRecords,
  getFilteredRecords,
};

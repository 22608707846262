import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Ratio from 'react-ratio';
import { Link } from 'react-router-dom';
import { getPublishingDate } from '../../helpers/utils';
import Pin from '../../assets/svg/pin.svg';
import Calendar from '../../assets/svg/calendar.svg';
import Skyline from '../../assets/img/skyline.jpg';

export default class PropertyCard extends Component {
  static propTypes = {
    activity: PropTypes.string,
    address: PropTypes.string,
    area: PropTypes.number,
    type: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.any,
    propertyId: PropTypes.string,
    publishingDate: PropTypes.string,
    structure: PropTypes.string,
  };

  static defaultProps = {
    price: '',
  }

  render() {
    const {
      activity,
      address,
      area,
      type,
      image,
      name,
      price,
      propertyId,
      publishingDate,
      structure,
    } = this.props;
    const formatedPublishingDate = getPublishingDate(publishingDate);

    return (
      <div className='PropertyCard'>
        <div className='PropertyCard-contentWrapper'>
          <Ratio
            className='PropertyCard-imageWrapper'
            ratio={ 16 / 9 }
          >
            <img
              src={ image || Skyline }
              alt={ name }
              className='PropertyCard-image'
            />
            <div className='PropertyCard-priceWrapper'>
              <span className='PropertyCard-price'>
                {` ${price} €`}
              </span>
              { activity }
            </div>
          </Ratio>
          <div className='PropertyCard-textWrapper'>
            <div className='PropertyCard-details'>
              <Link
                to={ `/detailPage/${propertyId}` }
                className='PropertyCard-name'
              >
                { name }
              </Link>
              <span className='PropertyCard-address'>
                <img src={ Pin } alt='pin' className='PropertyCard-pin' />
                { address }
              </span>
            </div>
            <div className='PropertyCard-facilities'>
              { type && (
                <div className='PropertyCard-facility'>
                  <span className='PropertyCard-facilityTitle'>Nekretnina</span>
                  <span className='PropertyCard-facilityValue'>{ type }</span>
                </div>
              )}
              { structure && (
                <div className='PropertyCard-facility'>
                  <span className='PropertyCard-facilityTitle'>Struktura</span>
                  <span className='PropertyCard-facilityValue'>{ structure }</span>
                </div>
              )}
              { area && (
                <div className='PropertyCard-facility'>
                  <span className='PropertyCard-facilityTitle'>Površina</span>
                  <span className='PropertyCard-facilityValue'>{ `${area} m2` }</span>
                </div>
              )}
            </div>
            <div className='PropertyCard-footer'>
              <img
                src={ Calendar }
                alt='calendar'
                className='PropertyCard-calendar'
              />
              <span className='PropertyCard-date'>
                {`${formatedPublishingDate}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

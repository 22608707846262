import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import animateScrollTo from 'animated-scroll-to';
import { withWindow } from 'react-window-decorators';
import { getFilteredRecords } from '../../helpers/utils';
import ViewWrapper from '../ViewWrapper';
import NewOffers from '../../components/NewOffers/NewOffers';
import SearchForm from '../../components/SearchForm/SearchForm';
import ResultsList from '../../components/ResultsList/ResultsList';

function getSearchParams(paramsData) {
  const params = new URLSearchParams(paramsData.search);
  return {
    activity: params.get('activity'),
    type: params.get('type'),
    structure: params.get('structure'),
    location: params.get('location'),
    minPrice: params.get('minPrice'),
    maxPrice: params.get('maxPrice'),
  };
}

const Results = ({ location, data, breakpoint }) => {
  const [showLoader, setShowLoader] = useState(false)
  const searchParams = getSearchParams(location);
  const filteredRecords = getFilteredRecords(data.records, searchParams);

  useEffect(() => {
    animateScrollTo(0);
  });

  const isSmallOrMedium = ['small', 'medium'].includes(breakpoint);

  return (
    <ViewWrapper isMenuFixed>
      <div className='Results'>
        <div className='Container'>
          <div className='Results-wrapper'>
            <div className='Results-sidebar'>
              <SearchForm 
                searchParams={ searchParams } 
                setShowLoader={ setShowLoader }
              />
              { !isSmallOrMedium && <NewOffers data={ data.records } /> }
            </div>
            <ResultsList 
              data={ filteredRecords } 
              showLoader={ showLoader }
            />
            { isSmallOrMedium && 
              <div className='Results-sidebar'>
                <NewOffers data={ data.records } /> 
              </div>
            }
          </div>
        </div>
      </div>
    </ViewWrapper>
  );
};

Results.propTypes = {
  breakpoint: PropTypes.string,
  data: PropTypes.object,
  location: PropTypes.object,
};

export default withWindow(Results);

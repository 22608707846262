import React from 'react';
import MerkurLogo from '../../assets/img/merkur-logo.png';
import { ReactComponent as Pin } from '../../assets/svg/pin.svg';
import { ReactComponent as Mail } from '../../assets/svg/mail.svg';
import { ReactComponent as Phone } from '../../assets/svg/phone2.svg';
import { ReactComponent as Plane } from '../../assets/svg/sent-mail.svg';

const Footer = () => {
  return (
    <div className='Footer'>
      <div className='Footer-contentWrapper'>
        <div className='Footer-blockWrapper'>
          <div className='Footer-block'>
            <img src={ MerkurLogo } alt='logo' className='Footer-logo' />
            <p className='Footer-text'>
              Možete nas kontaktirati svakog radnog dana od 9 do 14h i od 18h do 20h,
              kao i subotom od 9 do 14h.
            </p>
          </div>
          <div className='Footer-block'>
            <h4 className='Footer-blockTitle'>Kontaktirajte nas</h4>
            <p className='Footer-textLine'>
              <Pin className='Footer-icon' />
              Tomislava Andrića Džigija 45, 36000 Kraljevo
            </p>
            <p className='Footer-textLine'>
              <Mail className='Footer-icon' />
              <a href='mailto:nekretninemerkur@open.telekom.rs'>nekretninemerkur@open.telekom.rs</a>
            </p>
            <p className='Footer-textLine'>
              <Phone className='Footer-icon' />
              <a href='tel:+38103633668'>(+381) 036 333 668</a>
            </p>
            <p className='Footer-textLine'>
              <Phone className='Footer-icon' />
              <a href='tel:+3810637703523'>(+381) 063 7703 523</a>
            </p>
            <p className='Footer-textLine'>
              <Phone className='Footer-icon' />
              <a href='tel:+3810637703524'>(+381) 063 7703 524</a>
            </p>
          </div>
          <div className='Footer-block'>
            <h4 className='Footer-blockTitle'>Pitanja?</h4>
            <form className='Footer-form' action='https://formspree.io/nekretninemerkur@mts.rs' method='POST'>
              <textarea className='Footer-textarea' name='message' placeholder='Vaša poruka' />
              <div className='Footer-inputWrapper'>
                <input
                  type='text'
                  className='Footer-input'
                  name='_replyto'
                  placeholder='Vaša Email Adresa'
                />
                <button
                  type='submit'
                  className='Footer-button'
                >
                  <Plane className='Footer-buttonIcon' />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='Footer-subfooter'>
          © 2018
          <a href='/'>Merkur Nekretnine</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

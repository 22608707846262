import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InfoCard extends Component {
  static propTypes = {
    alt: PropTypes.string,
    icon: PropTypes.func,
    text: PropTypes.string,
    title: PropTypes.string,
  }

  render() {
    const {
      alt,
      icon,
      text,
      title,
    } = this.props;

    const CardIcon = icon;

    return (
      <div className='InfoCard'>
        <div className='InfoCard-iconWrapper'>
          <CardIcon alt={ alt } className='InfoCard-icon' />
        </div>
        <h3 className='InfoCard-title'>
          { title }
        </h3>
        <p className='InfoCard-text'>{ text }</p>
      </div>
    );
  }
}

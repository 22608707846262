import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropertyCard from '../PropertyCard/PropertyCard';
import Loader from '../Loader/Loader';

class ResultsList extends Component {
  static propTypes = {
    data: PropTypes.array,
    showLoader: PropTypes.bool,
  };

  renderResults() {
    const {
      data,
    } = this.props;

    if (data.length === 0) {
      return <p className='ResultsList-message'>Trenutno nema oglasa koji ispunjavaju tražene uslove.</p>;
    }

    return data.map((item, index) => {
      const {
        Fotografije,
        Lokacija,
        Naziv,
        Struktura,
        Tip
      } = item.fields;
      const Površina = item.fields['Površina (m²)'];
      const publishingDate = item.fields['Datum Objave'];
      const activity = item.fields['Tip Oglasa'];
      const price = item.fields['Cena (€)'];

      return (
        <div
          key={ `${Naziv}-${index}` } //eslint-disable-line
          className='ResultsList-cardWrapper'
        >
          <PropertyCard
            propertyId={ item.id }
            activity={ activity }
            image={ Fotografije && Fotografije[0].thumbnails.large.url }
            name={ Naziv }
            address={ Lokacija }
            area={ Površina }
            type={ Tip }
            structure={ Struktura }
            price={ price }
            publishingDate={ publishingDate }
          />
        </div>
      );
    });
  }

  render() {
    const {
      showLoader,
    } = this.props;

    return (
      <div className='ResultsList'>
        { showLoader ? <Loader /> : this.renderResults() }
      </div>
    );
  }
}

export default ResultsList;

import React, { Component } from 'react';
import { withWindow } from 'react-window-decorators';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import PropertyCard from '../PropertyCard/PropertyCard';

const SLIDES_TO_SHOW = {
  small: 1,
  medium: 2,
  large: 3,
  xlarge: 3,
};

class Carousel extends Component {
  static propTypes = {
    breakpoint: PropTypes.string,
    data: PropTypes.array,
  };

  renderCarouselItems = () => {
    const {
      data,
    } = this.props;

    return data.map((item, index) => {
      const {
        Fotografije,
        Lokacija,
        Naziv,
        Struktura,
        Tip,
      } = item.fields;
      const Površina = item.fields['Površina (m²)'];
      const publishingDate = item.fields['Datum Objave'];
      const activity = item.fields['Tip Oglasa'];
      const price = item.fields['Cena (€)'];

      return (
        <div
          className='Carousel-cardWrapper'
          key={ `${Naziv}-${index}` } //eslint-disable-line
        >
          <PropertyCard
            activity={ activity }
            address={ Lokacija }
            area={ Površina }
            image={ Fotografije && Fotografije[0].thumbnails.large.url }
            name={ Naziv }
            price={ price }
            propertyId={ item.id }
            publishingDate={ publishingDate }
            structure={ Struktura }
            type={ Tip }
          />
        </div>
      );
    });
  }

  render() {
    const {
      breakpoint,
    } = this.props;

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: SLIDES_TO_SHOW[breakpoint],
      slidesToScroll: 1,
    };

    return (
      <div className='Carousel'>
        <Slider { ...settings }>
          { this.renderCarouselItems() }
        </Slider>
      </div>
    );
  }
}

export default withWindow(Carousel);

import React from 'react';

const WhatWeDo = () => {
  return (
    <div className='WhatWeDo Container'>
      <ul className='WhatWeDo-block'>
        <li className='WhatWeDo-item'>Stručna pomoć u realizaciji kupovine i prodaje nepokretnosti</li>
        <li className='WhatWeDo-item'>Pomoć u proceni vrednosti nekretnine</li>
        <li className='WhatWeDo-item'>Oglašavanje nekretnine u dnevnoj štampi, na internetu i na svom sajtu</li>
        <li className='WhatWeDo-item'>Pregled dokumentaciju i pružiti advokatske usluge i savete u pogledu upisa prava svojine u javne knjige i katastar nepokretnosti</li>
        <li className='WhatWeDo-item'>Prezentirati nepokretnost, dovesti potencijalnog kupca</li>
      </ul>
      <ul className='WhatWeDo-block'>
        <li className='WhatWeDo-item'>U skladu sa Vašim željama i potrebama, pronaći adekvatnu nepokretnost i omogućiti njihovog razgledanje</li>
        <li className='WhatWeDo-item'>Pružiti Vam pomoć pri odlučivanju o stambenom kreditu s obzirom da sarađujemo sa velikim brojem banaka i pomažemo u prikupljanju potrebnu dokumentacije vezane za nepokretnost za koju se odobrava stambeni kredit</li>
        <li className='WhatWeDo-item'>Sačinjavamo kupoprodajni ugovor i pružamo pomoć u pogledu dalje realizacije (oslobađanja poreza na prenos apsolutnih prava –potrebna dokumentacija, povraćaj PDV-a, uknjižba nepokretnosti)</li>
      </ul>
    </div>
  );
};

export default WhatWeDo;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withWindow } from 'react-window-decorators';
import animateScrollTo from 'animated-scroll-to';
import ViewWrapper from '../ViewWrapper';
import Header from '../../components/Header/Header';
import Carousel from '../../components/Carousel/Carousel';
import AboutUs from '../../components/AboutUs/AboutUs';
import WhatWeDo from '../../components/WhatWeDo/WhatWeDo';

class Home extends Component {
  static propTypes = {
    breakpoint: PropTypes.string,
    data: PropTypes.object,
    location: PropTypes.object,
  };

  componentDidMount() {
    const {
      location,
    } = this.props;
    
    if (location.hash) {
      this.scrollToSection(location.hash);
    }
  }

  componentDidUpdate(prevProp) {
    const {
      location,
    } = this.props;

    if (location.hash !== prevProp.location.hash) {
      this.scrollToSection(location.hash);
    }
  }

  scrollToSection = (section) => {
    const { 
      breakpoint, 
    } = this.props;
    const isSmallOrMedium = ['small', 'medium'].includes(breakpoint);

    const headerHeight = document.getElementById('header').offsetHeight;
    const newRecordsHeight = document.getElementById('newRecords').offsetHeight;
    const aboutUsHeight = document.getElementById('aboutUs').offsetHeight;
    const whatWeDoHeight = document.getElementById('whatWeDo').offsetHeight;

    const additionalValue = isSmallOrMedium ? -60 : 50;
    
    const topValue = {
      '#new-records': headerHeight - additionalValue,
      '#about-us': headerHeight + newRecordsHeight - additionalValue,
      '#what-we-do': headerHeight + newRecordsHeight + aboutUsHeight - additionalValue,
      '#contact-us': headerHeight + newRecordsHeight + aboutUsHeight + whatWeDoHeight - additionalValue,
    };

    if (topValue[section]) {
      animateScrollTo(topValue[section]);
    } else {
      animateScrollTo(0);
    }
  }

  render() {
    const {
      data,
    } = this.props;

    return (
      <ViewWrapper>
        <Header />
        <div id='newRecords' className='Home-section'>
          <div className='Home-sectionTitleWrapper'>
            <h1 className='Home-sectionTitle'>Novo u ponudi</h1>
          </div>
          <Carousel data={ data.records } />
        </div>
        <div id='aboutUs' className='Home-section Home-section--aboutUs'>
          <div className='Home-sectionTitleWrapper'>
            <h1 className='Home-sectionTitle'>O nama</h1>
            <p className='Home-sectionSubtitle'>
              Šta treba znati o agenciji Merkur?
            </p>
          </div>
          <AboutUs />
        </div>
        <div id='whatWeDo' className='Home-section'>
          <div className='Home-sectionTitleWrapper'>
            <h1 className='Home-sectionTitle'>Šta radimo</h1>
          </div>
          <WhatWeDo />
        </div>
      </ViewWrapper>
    );
  }
}

export default withWindow(withRouter(Home));

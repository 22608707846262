import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withWindow } from 'react-window-decorators';
import SearchBar from '../SearchBar/SearchBar';
import SeachBox from '../SearchBox/SearchBox';
import Phone from '../../assets/svg/phone.svg'; //eslint-disable-line

class Header extends Component {
  static propTypes = {
    breakpoint: PropTypes.string,
  };

  state = {
    isSearchBoxOpen: false,
  };

  handleSearchBoxButtonClick = () => {
    const { isSearchBoxOpen } = this.state;

    this.setState({
      isSearchBoxOpen: !isSearchBoxOpen,
    });
  }

  render() {
    const { isSearchBoxOpen } = this.state;
    const { breakpoint } = this.props;
    const isSmallOrMedium = ['small', 'medium'].includes(breakpoint);

    return (
      <div id='header' className='Header'>
        <div className='Header-imageWrapper'>
          <div className='Header-overlay' />
          <div className='Header-contentWrapper'>
            <div className='Header-textWrapper'>
              <h1 className='Header-title'>
                Pronađi svoj dom
              </h1>
              <p className='Header-text'>
                Pretraži našu ponudu za prodaju i izdavanje nekretnina
              </p>
              { !isSmallOrMedium &&
                <SearchBar />
              }
            </div>
            <div className='Header-numbersWrapper'>
              <span className='Header-number'>
                <img src={ Phone } alt='phone' className='Header-icon' />
                (+381) 036 333 668
              </span>
              <span className='Header-number'>
                <img src={ Phone } alt='phone' className='Header-icon' />
                (+381) 063 7703 523
              </span>
              <span className='Header-number'>
                <img src={ Phone } alt='phone' className='Header-icon' />
                (+381) 063 7703 524
              </span>
            </div>
          </div>
        </div>
        <SeachBox
          isSearchBoxOpen={ isSearchBoxOpen }
          handleSearchBoxButtonClick={ this.handleSearchBoxButtonClick }
        />
        <button
          type='button'
          className='Header-searchBoxButton'
          onClick={ this.handleSearchBoxButtonClick }
        >
          { isSearchBoxOpen ? 'Zatvori' : 'Pretraži' }
        </button>
      </div>
    );
  }
}

export default withWindow(Header);

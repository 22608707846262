import React, { Component } from 'react';
import { WindowManager } from 'react-window-decorators';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import Home from './views/Home/Home';
import Results from './views/Results/Results';
import ViewWrapper from './views/ViewWrapper';
import DetailPage from './views/DetailPage/DetailPage';

const API = 'https://yxke12hnfa.execute-api.eu-central-1.amazonaws.com/prod/fetch';

const BREAKPOINTS = [
  {
    name: 'small',
    media: '(min-width: 0)',
  },
  {
    name: 'medium',
    media: '(min-width: 768px)',
  },
  {
    name: 'large',
    media: '(min-width: 992px)',
  },
  {
    name: 'xlarge',
    media: '(min-width: 1200px)',
  },
];

// eslint-disable-next-line no-new
new WindowManager(BREAKPOINTS);

class App extends Component {
  state = {
    data: null,
    error: null,
  };

  componentDidMount() {
    fetch(API).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to fatch data');
    })
      .then(data => this.setState({ data: data.data }))
      .catch(error => this.setState({ error }));
  }

  render() {
    const { data, error } = this.state;

    if (error) {
      return (
        <Router>
          <ViewWrapper>
            <ErrorMessage />
          </ViewWrapper>
        </Router>
      );
    }

    if (!data) {
      return (
        <Router>
          <ViewWrapper>
            <Loader />
          </ViewWrapper>
        </Router>
      );
    }

    return (
      <Router>
        <div className='App'>
          <Route
            exact
            path='/'
            render={ props => <Home { ...props } data={ data } /> }
          />
          <Route
            path='/results'
            render={ props => <Results { ...props } data={ data } /> }
          />
          <Route
            path='/detailPage/:id'
            render={ props => <DetailPage { ...props } data={ data.records } /> }
          />
        </div>
      </Router>
    );
  }
}

export default App;

import React from 'react';
import PropTypes from 'prop-types';

const SearchFormWidget = ({
  children,
  title,
}) => {
  return (
    <div className='SearchFormWidget'>
      <h3 className='SearchFormWidget-title'>
        { title }
      </h3>
      <div className='SearchFormWidget-border' />
      { children }
    </div>
  );
};

SearchFormWidget.propTypes = {
  children: PropTypes.array,
  title: PropTypes.string,
};

export default SearchFormWidget;

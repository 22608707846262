import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import { withRouter, Link } from 'react-router-dom';
import Slider from '../Slider/Slider';
import Search from '../../assets/svg/search.svg'; //eslint-disable-line
import { SEARCH_BAR_DATA } from '../../constants/global';

const SearchBar = () => {
  const [activity, setActivity] = useState('');
  const [type, setType] = useState('');
  const [structure, setStructure] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState([0, 150000]);

  const searchString =
    `?activity=${activity}&type=${type}&structure=${structure}&location=${location}&minPrice=${price[0]}&maxPrice=${price[1]}`;

  return (
    <div className='SearchBar'>
      <Dropdown
        arrowClosed={ <span className='SearchBar-arrow--down' /> }
        arrowOpen={ <span className='SearchBar-arrow--up' /> }
        className='SearchBar-dropdown'
        controlClassName='SearchBar-dropdownControll'
        menuClassName='SearchBar-dropdownMenu'
        options={ SEARCH_BAR_DATA[0] }
        value={ activity }
        onChange={ e => setActivity(e.value) }
        placeholder='Tip oglasa'
      />
      <Dropdown
        arrowClosed={ <span className='SearchBar-arrow--down' /> }
        arrowOpen={ <span className='SearchBar-arrow--up' /> }
        className='SearchBar-dropdown'
        controlClassName='SearchBar-dropdownControll'
        menuClassName='SearchBar-dropdownMenu'
        options={ SEARCH_BAR_DATA[1] }
        value={ type }
        onChange={ e => setType(e.value) }
        placeholder='Tip nekretnine'
      />
      <Dropdown
        arrowClosed={ <span className='SearchBar-arrow--down' /> }
        arrowOpen={ <span className='SearchBar-arrow--up' /> }
        className='SearchBar-dropdown'
        controlClassName='SearchBar-dropdownControll'
        menuClassName='SearchBar-dropdownMenu'
        options={ SEARCH_BAR_DATA[2] }
        value={ structure }
        onChange={ e => setStructure(e.value) }
        placeholder='Struktura'
      />
      <Dropdown
        arrowClosed={ <span className='SearchBar-arrow--down' /> }
        arrowOpen={ <span className='SearchBar-arrow--up' /> }
        className='SearchBar-dropdown'
        controlClassName='SearchBar-dropdownControll'
        menuClassName='SearchBar-dropdownMenu'
        options={ SEARCH_BAR_DATA[3] }
        value={ location }
        onChange={ e => setLocation(e.value) }
        placeholder='Lokacija'
      />
      <div className='SearchBar-sliderWrapper'>
        <Slider price={ price } setPrice={ setPrice } />
      </div>
      <Link
        to={ { pathname: '/results', search: searchString } }
        className='SearchBar-button'
      >
        <img src={ Search } alt='magnifying glass' className='SearchBar-searchIcon' />
          Traži
      </Link>
    </div>
  );
};

export default withRouter(SearchBar);

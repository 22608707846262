import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withWindow, withScroll } from 'react-window-decorators';
import cx from 'classnames';
import logo from '../../assets/img/merkur-logo.png';
import logoBlack from '../../assets/img/merkur-logo-black.png';
import animateScrollTo from 'animated-scroll-to';

class Menu extends Component {
  static propTypes = {
    breakpoint: PropTypes.string,
    isMenuFixed: PropTypes.bool,
    scrollPosition: PropTypes.number,
  };

  state = {
    isMenuOpen: false,
    whiteMenu: false,
    backToTopVisible: false,
  };

  componentWillReceiveProps(nextProps) {
    const {
      scrollPosition,
    } = this.props;
    const {
      whiteMenu,
      backToTopVisible,
    } = this.state;

    if (nextProps.scrollPosition !== scrollPosition) {
      if (nextProps.scrollPosition > 100 && !whiteMenu) {
        this.setState({
          whiteMenu: true,
        });
      }

      if (nextProps.scrollPosition < 100 && whiteMenu) {
        this.setState({
          whiteMenu: false,
        });
      }

      if (nextProps.scrollPosition > 300 && !backToTopVisible) {
        this.setState({
          backToTopVisible: true,
        });
      }

      if (nextProps.scrollPosition < 300 && backToTopVisible) {
        this.setState({
          backToTopVisible: false,
        });
      }
    }
  }

  toggleMenu = () => {
    const { isMenuOpen } = this.state;

    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  }

  handleBackToTopClick = () => {
    animateScrollTo(0, 0);
  }

  render() {
    const {
      breakpoint,
      isMenuFixed,
    } = this.props;
    const {
      backToTopVisible,
      isMenuOpen,
      whiteMenu,
    } = this.state;

    const isSmallOrMedium = ['small', 'medium'].includes(breakpoint);
    const hamburgerClasses = cx(
      'Menu-hamburger',
      'hamburger',
      'hamburger--squeeze',
      { 'is-active': isMenuOpen },
    );

    const linksWrapperClasses = cx(
      'Menu-linksWrapper',
      { 'Menu-linksWrapper--open': isMenuOpen },
    );

    const menuClasses = cx(
      'Menu',
      { 'Menu--white': (whiteMenu && !isMenuFixed) || isMenuFixed },
    );

    const backToTopButtonClasses = cx(
      'Menu-backToTop',
      { 'Menu-backToTop--visible': backToTopVisible },
    );

    const merkurLogo = (whiteMenu && !isMenuFixed) || isMenuFixed || isSmallOrMedium ? logoBlack : logo;

    return (
      <div className={ menuClasses }>
        <div className='Menu-wrapper'>
          <Link to='/'>
            <img className='Menu-logo' src={ merkurLogo } alt='company logo' />
          </Link>
          { isSmallOrMedium ? (
            <button
              type='button'
              onClick={ this.toggleMenu }
              className={ hamburgerClasses }
            >
              <span className='hamburger-box'>
                <span className='hamburger-inner' />
              </span>
            </button>
          ) : (
            <div className='Menu-links'>
              <Link
                className='Menu-link'
                to='/#new-records'
              >
                Novo u ponudi
              </Link>
              <Link
                className='Menu-link'
                to='/#about-us'
              >
                O nama
              </Link>
              <Link
                className='Menu-link'
                to='/#what-we-do'
              >
                Šta radimo
              </Link>
              <Link
                className='Menu-link'
                to='/#contact-us'
              >
                Kontakt
              </Link>
              <Link className='Menu-link' to='/results'>Pretraga</Link>
            </div>
          )}
        </div>
        { isSmallOrMedium && (
          <div className={ linksWrapperClasses }>
            <Link
              className='Menu-linkSmall'
              to='/#new-records'
              onClick={ this.toggleMenu }
            >
              Novo u ponudi
            </Link>
            <Link
              className='Menu-linkSmall'
              to='/#about-us'
              onClick={ this.toggleMenu }
            >
              O nama
            </Link>
            <Link
              className='Menu-linkSmall'
              to='/#what-we-do'
              onClick={ this.toggleMenu }
            >
              Šta radimo
            </Link>
            <Link
              className='Menu-linkSmall'
              to='/#contact-us'
              onClick={ this.toggleMenu }
            >
              Kontakt
            </Link>
            <Link
              className='Menu-linkSmall'
              to='/results'
              onClick={ this.toggleMenu }
            >
              Pretraga
            </Link>
          </div>
        )}
        <button
          type='button'
          className={ backToTopButtonClasses }
          onClick={ this.handleBackToTopClick }
        >
          <div className='Menu-arrow--up' />
        </button>
      </div>
    );
  }
}

export default withScroll(withWindow(withRouter(Menu)));

import React from 'react';

const ErrorMessage = () => {
  return (
    <div className='ErrorMessage'>
      Sajt je trenutno nedostupan. Molimo Vas pokušajte kasnije.
    </div>
  );
}

export default ErrorMessage;
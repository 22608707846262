import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../components/Menu/Menu';
import Footer from '../components/Footer/Footer';

const ViewWrapper = ({ children, isMenuFixed }) => {
  return (
    <div className='ViewWrapper'>
      <Menu isMenuFixed={ isMenuFixed } />
      { children }
      <Footer />
    </div>
  );
};

ViewWrapper.propTypes = {
  children: PropTypes.any,
  isMenuFixed: PropTypes.bool,
};

export default ViewWrapper;

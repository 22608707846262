import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Checkmark } from '../../assets/svg/checkmark.svg';

const DetailPageDescription = ({ data }) => {
  const {
    Opis,
    Karakteristike,
  } = data;

  return (
    <div className='DetailPageDescription'>
      <h1 className='DetailPageDescription-title'>Opis</h1>
      <p className='DetailPageDescription-text'>{ Opis }</p>
      <h1 className='DetailPageDescription-title'>Karakteristike</h1>
      <div className='DetailPageDescription-features'>
        { Karakteristike && Karakteristike.map((feature) => {
          return (
            <div className='DetailPageDescription-feature'>
              <Checkmark className='DetailPageDescription-checkmark' />
              { feature }
            </div>
          );
        })}
      </div>
    </div>
  );
};

DetailPageDescription.propTypes = {
  data: PropTypes.object,
};

export default DetailPageDescription;

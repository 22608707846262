import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getSortedRecords, getPublishingDate } from '../../helpers/utils';
import SearchFormWidget from '../SearchFormWidget/SearchFormWidget';
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';

function renderNewOffers(offers) {
  return offers.map((offer) => {
    const name = offer.fields.Naziv;
    const price = offer.fields['Cena (€)'];
    const date = getPublishingDate(offer.fields['Datum Objave']);
    const photo = offer.fields.Fotografije && offer.fields.Fotografije[0].thumbnails.small.url;
    return (
      <Link
        key={ name }
        to='/'
        className='NewOffers-wrapper'
      >
        <div className='NewOffers-image' style={ { backgroundImage: `url(${photo})` } } />
        <div className='NewOffers-info'>
          <h5 className='NewOffers-title'>
            { name }
          </h5>
          <div className='NewOffers-price'>
            { `€${price} |` }
            <Calendar className='NewOffers-icon' />
            { date }
          </div>
        </div>
      </Link>
    );
  });
}


const NewOffers = ({ data }) => {
  const sortedRecords = getSortedRecords(data, 3);

  return (
    <SearchFormWidget title='Nove u ponudi'>
      { renderNewOffers(sortedRecords) }
    </SearchFormWidget>
  );
};

NewOffers.propTypes = {
  data: PropTypes.array,
};

export default NewOffers;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Slider,
  Rail,
  Handles,
  Tracks,
} from 'react-compound-slider';
import { Handle, Track } from '../SliderComponents/SliderComponents';

export default class HomepageSlider extends Component {
  static propTypes = {
    price: PropTypes.array,
    setPrice: PropTypes.func,
  };

  onUpdate = (values) => {
    const { setPrice } = this.props;
    setPrice(values);
  }

  render() {
    const { price } = this.props;

    return (
      <div className='Slider'>
        <div className='Slider-title'>Cena</div>
        <Slider
          className='Slider-wrapper'
          domain={ [0, 150000] }
          step={ 1 }
          mode={ 2 }
          values={ [price ? price[0] : 0, price ? price[1] : 15000] }
          onUpdate={ this.onUpdate }
        >
          <Rail>
            {({ getRailProps }) => (
              <div className='Slider-rail' { ...getRailProps() } />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className='Slider-handles'>
                {handles.map(handle => (
                  <Handle
                    key={ handle.id }
                    handle={ handle }
                    getHandleProps={ getHandleProps }
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={ false } right={ false }>
            {({ tracks, getTrackProps }) => (
              <div className='Slider-tracks'>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={ id }
                    source={ source }
                    target={ target }
                    getTrackProps={ getTrackProps }
                  />
                ))}
              </div>
            )}
          </Tracks>
          <span className='Slider-value Slider-value--left'>{ `${price && price[0]} EUR` }</span>
          <span className='Slider-value Slider-value--right'>{ `${price && price[1]} EUR` }</span>
        </Slider>
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

function getGalleryImages(images) {
  return images.map((image) => {
    return {
      original: image.thumbnails.large.url,
      thumbnail: image.thumbnails.small.url,
    };
  });
}

const DetailPageGallery = ({ images }) => {
  const galleryImages = getGalleryImages(images);

  return (
    <ImageGallery
      additionalClass='DetailPageGallery'
      items={ galleryImages }
      showFullscreenButton={ false }
      showPlayButton={ false }
    />
  );
};

DetailPageGallery.propTypes = {
  images: PropTypes.array,
};

export default DetailPageGallery;
